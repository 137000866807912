let ua,
	isSP = false,
	isTAB = false,
	isIE = false,
	event_type = 'click';

// imgLoader vars
let img_src_ary = [],
	loaded_img_len = 0;

let ww,wh,
	$header,$footer,headerH,$gnavMenu,gnavMenuH,photoGalleryH,$opening,
    isImg;

;(function($,window,undefind) {
	let $window = $(window),
		$document = $(document);

	/* -----------------------------------------------
	 * Init Handler
	 * ----------------------------------------------- */
	initialize();

	/* -----------------------------------------------
	 * Start Handler
	 * ----------------------------------------------- */
	window.onLoad = startAnimation();

	/* -----------------------------------------------
	 * Init
	 * ----------------------------------------------- */
	function initialize(){
		ww = $window.width();
		wh = $window.height();
		$header = $('#header');
		$footer = $('#footer');
		headerH = $header.height();
		$gnavMenu = $('.l-gnav__mainmenu');
		gnavMenuH = $gnavMenu.height();
		$opening = $('.js-opening');
		getUA();
	};

	/* -----------------------------------------------
	 * Start Contents
	 * ----------------------------------------------- */
	function startAnimation(){
		initContent(isSP, isTAB, isIE, event_type);
	}

	function initContent(_sp, _tab, _ie, _e){
		trace('func', 'initContent');
		event_type = _e;
		isSP = _sp;
		isTAB = _tab;
	  	isIE = _ie;
		$window = $(window);
		// TOPTOPの場合はムービーを再生
		if ($('.js-opening').length > 0){
			setOpeningMuvie();
		} else {
			setup();
		}
	};


	/* -----------------------------------------------
	 * コンテンツの初期化
	 * ----------------------------------------------- */
	function setup(){
		trace('func', 'setup');

		if (!isSP && !isTAB) {
			$('.fademask a').hover(
				function(){
					$(this).parents('.fademask').addClass('hover');
				},
				function(){
					$(this).parents('.fademask').removeClass('hover');
				}
			);

			$('.fadeover a').hover(
				function(){
					$(this).parents('.fadeover').addClass('hover');
				},
				function(){
					$(this).parents('.fadeover').removeClass('hover');
				}
			);
		}

		// Tab
		$('.js-tab--menu a').on(event_type, function(e) {
			e.preventDefault();
			if ($('.js-tab--content').length > 1) {
				$('.js-tab--content').css({display: 'none', opacity: 0});
				$($(this).attr('href')).stop().css({display: 'block'}).animate({opacity: 1});
				$('.js-tab--menu a').removeClass('active');
				$(this).toggleClass('active');
				return false;
			}
		});

		fixedFooter();
		setGlobalMenu();
		setAccordion();
		setNewsAccordion();
		setMap();
		setResponsiveImage();
		setAdjustHeight();

		// ニュース アコーディオン
		if ($('.page-news').length > 0) {
			openNews();
		}

		// スライダー用のクラスがあれば呼び出し
		if ($('.js-slider').length > 0){
			setSlider();
		}

		// form validation check
		$('.js-validateForm').on(event_type, function(e) {
			validateForm();
		});

		setResize();
	}

	/* -----------------------------------------------
	 *  TOPTOP用動画再生
	 * ----------------------------------------------- */
	function setOpeningMuvie() {
		$opening.css({height: wh});
		if (!isSP && !isTAB) {
			$('.js-opening-loading').addClass('is-active');
			$(".js-opening-movie .player").YTPlayer({
				videoURL:'https://www.youtube.com/watch?v=https://youtu.be/1q7dXM-IL3s',
				containment:'#opening',
				autoPlay:true,
				mute:true,
				startAt:0,
				opacity:1,
				quality: 'large',
				stopMovieOnBlur:false,
				onReady : function(){
					$('.js-opening-bg').addClass('opacity');
					$('.js-opening-loading').removeClass('is-active');
					$('.js-opening-btn').addClass('is-active');
				}
			});
		} else {
			$('.js-opening-bg').addClass('bgImg');
			$('.js-opening-btn').css({opacity: 1});
		}

		function resize(){
			var wh = $(window).height();
			$opening.css({height: wh});
		};

		$window.on('load resize',resize);
	}

	/* -----------------------------------------------
	 *  フッターを画面下部に固定
	 * ----------------------------------------------- */
	function fixedFooter() {
		let mainH = wh - $footer.height();
		if ($(".js-set-content").height() < mainH) {
			$(".js-set-content").css({"height": mainH});
			$footer.addClass(".is-fixed")
		}
	}

	/* -----------------------------------------------
	 *  フッターを画面下部に固定
	 * ----------------------------------------------- */
	function setGlobalMenu() {
		var gFlag = false;
		$('.js-drawer').on(event_type, function(e) {
			if (!gFlag) {
				$(this).addClass('is-active');
				$gnavMenu
					.css({height: 0, display: 'inline-block'})
					.stop().animate(
						{height: wh - headerH, opacity: 1 },
						{duration: 400});
				gFlag = true;
			} else {
				$(this).removeClass('is-active');
				$gnavMenu
					.animate(
						{ height: 0, opacity: 0 },
						{
							duration: 400,
							complete: function(){
								$(this).css({display: 'none'});
							}
						});
				gFlag = false;
			}
		});
	}

	/* -----------------------------------------------
	 *  アコーディオン
	 * ----------------------------------------------- */
	function setAccordion() {
		if (ww > 900) {
			$('.js-accordion').hover(function () {
				$(this).find('ul').stop(true, true).slideToggle();
			}).find('ul').hide();
		} 
	}

	/* -----------------------------------------------
	 *  アコーディオン
	 * ----------------------------------------------- */
	function setNewsAccordion(){
		$('.js-news-accordion dt a').on("click", function() {
			if ($(this).parents('dt').hasClass('is-active')) {
				$(this).parents('dt').next('dd').slideUp(500);
				$(this).parents('dt').removeClass("is-active");
			} else {
				$('.js-news-accordion dd').slideUp(500);
				$(this).parents('dt').next('dd').slideDown(500);
				$('.js-news-accordion dt').removeClass("is-active");
				$(this).parents('dt').addClass("is-active");
			}
		});
		$('.js-news-close').on("click", function() {
			if ($(this).parents('.js-news-accordion').find('dt').hasClass('is-active')) {
				$(this).parents('.js-news-accordion').find('dd').slideUp(500);
				$(this).parents('.js-news-accordion').find('dt').removeClass("is-active");
			}
		});
	}

	/* -----------------------------------------------
	 *  URLのハッシュからニュースの記事IDを取得して街灯の「記事を展開
	 * ----------------------------------------------- */
	function openNews() {
		let urlHash,
			speed,
			newsId,
			position;
			
		function init() {
			urlHash = location.hash;

			// URLにアンカーが存在する場合
			if(urlHash){
				speed = 300;
				newsId = urlHash;
				position = $(newsId).offset().top - headerH;
				$("html, body").animate({scrollTop:position}, speed, "swing");
				setTimeout(() => {
					$('.js-news-accordion').find('dt').removeClass('is-active');
					$('.js-news-accordion').find('dd').slideUp(500);
					$(newsId).find('dt').addClass('is-active');
					$(newsId).find('dd').slideDown(500);
					return false;
				}, 500);
			}
		}
		$('.js-news-link').on("click", function() {
			init();
		});
		init();
	}

	function setScrollImg() {
		if (wh > ww * 1.3) {
			$('.js-kv--scroll').css({display: 'none'});
		} else {
			$('.js-kv--scroll').css({display: 'block'});
		}
	}

	function setResponsiveImage() {
		let src = "";
		let DATA_PC_IMG = "";
		let DATA_SP_IMG = "";
		if ($('.js-responsive').length > 0) {
			$('.js-responsive').each(function(){
				DATA_PC_IMG = $(this).attr("data-img-pc");
				DATA_SP_IMG = $(this).attr("data-img-sp");
				if (!isSP && !isTAB) {
					if( ww < 641){ 
						if($(this).attr("src")){
							$(this).attr("src",DATA_SP_IMG);
						} else {
							$(this).css({"background-image": "url("+DATA_SP_IMG+")"});
						}
					} else {
						if($(this).attr("src")){
							$(this).attr("src",DATA_PC_IMG);
						} else {
							$(this).css({"background-image": "url("+DATA_PC_IMG+")"});
						}
					}
				} else {
					if (wh > ww * 1.3) {
						if($(this).attr("src")){
							$(this).attr("src",DATA_SP_IMG);
						} else {
							$(this).css({"background-image": "url("+DATA_SP_IMG+")"});
						}
					} else {
						if($(this).attr("src")){
							$(this).attr("src",DATA_PC_IMG);
						} else {
							$(this).css({"background-image": "url("+DATA_PC_IMG+")"});
						}
					}
				}
			});
		}
		if ($('.js-adaptive').length > 0) {
			$('.js-adaptive').each(function(){
				DATA_PC_IMG = $(this).attr("data-img-pc");
				DATA_SP_IMG = $(this).attr("data-img-sp");
				if( wh > ww * 1.3 && ww <= 640){ 
					if($(this).attr('src')){
						$(this).attr("src",DATA_SP_IMG);
					} else {
						$(this).css({"background-image": "url("+DATA_SP_IMG+")"});
					}
				} else {
					if($(this).attr('src')){
						$(this).attr("src",DATA_PC_IMG);
					} else {
						$(this).css({"background-image": "url("+DATA_PC_IMG+")"});
					}
				}
			});
		}
	}

	/* -----------------------------------------------
	 *  高さ合わせ
	 * ----------------------------------------------- */
	function setAdjustHeight() {
		$('.js-adjustHeight').each((i, elm) => {
			let item = $(elm).find('.js-adjustHeight-item');
			//要素の高さを初期化
			$(item).css('height','');
			//要素の数を取得
			let count = item.length;
			//高さを代入する配列を初期化
			let itemHeight = [];
			//要素の数だけ高さを取得
			for(var i = 0; i < count; i++){
				itemHeight[i] = item.eq(i).height();
			}
			//高さを代入した配列の中から最大値を取得
			let maxHeight = Math.max.apply(null,itemHeight);
			item.height(maxHeight);
		});
	}

	function setSlider(){
		if ($('.js-slider--top').length > 0) {
			$('.js-slider--top li').height(wh);
			var sTop = $('.js-slider--top').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: false,
				onSlideAfter: function (currentIndex){
					sTop.startAuto();
				}
			});
		}
		if ($('.js-slider--room').length > 0) {
			var sRoom = $('.js-slider--room').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: true,
				onSlideAfter: function (currentIndex){
					sRoom.startAuto();
				}
			});
		}
		if ($('.js-slider--layout').length > 0) {
			var sLayout = $('.js-slider--layout').bxSlider( {
				mode: 'fade',
				controls    : true,
				auto        : false,
				speed       : 1000,
				pause       : 5000,
				pager		: false,
				onSlideBefore: function($slideElement, oldIndex, newIndex) {
					if ($('.js-room-types')) {
						$("[class^='js-room-type-']").css({display: 'none', opacity: 0});
						$('.js-room-type-'+ (newIndex + 1)).stop().css({display: 'block'}).animate({opacity: 1});
					}
				},
			});
		}
		if ($('.js-photoGallery').length > 0) {
			if (ww > 640) {
				$('js-photoGallery bx-viewport, .js-photoGallery-item').height($('.js-photoGallery-item').width() * 0.554);
			} else {
				$('js-photoGallery bx-viewport, .js-photoGallery-item').height(ww * 1.25);
			}
			var sKeimei = $('.js-slider--keimei').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: true,
				onSlideAfter: function (currentIndex){
					sKeimei.startAuto();
				}
			});
			var sHotaru = $('.js-slider--hotaru').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: true,
				onSlideAfter: function (currentIndex){
					sHotaru.startAuto();
				}
			});
			var sMaya = $('.js-slider--maya').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: true,
				onSlideAfter: function (currentIndex){
					sMaya.startAuto();
				}
			});
			var sAnniversary = $('.js-slider--anniversary').bxSlider( {
				mode: 'fade',
				controls    : false,
				auto        : true,
				speed       : 1000,
				pause       : 5000,
				pager		: true,
				onSlideAfter: function (currentIndex){
					sAnniversary.startAuto();
				}
			});
		}
	}

	function setMap() {
		if ($('#gmap').length > 0) {
			var data = new Array();
			data.push({
			lat: '36.697027', //緯度
			lng: '138.773523', //経度
			url: '//www.google.com/maps/place/%E5%9B%9B%E4%B8%87%E6%B8%A9%E6%B3%89+%E3%81%B2%E3%81%AA%E3%81%9F%E3%81%BF%E9%A4%A8+Shima+Onsen+Hinatamikan/@36.697872,138.77395,16z/data=!4m5!3m4!1s0x0:0x11acafd9ed2fbc21!8m2!3d36.69654!4d138.773534?hl=ja' //リンク先
		});

		let latlng = new google.maps.LatLng(data[0].lat, data[0].lng);
		let zoom = 16;
		if (ww <= 640) {
			zoom = 15;
		}

		let myOptions = {
			zoom: zoom,
			center: latlng,
			scrollwheel: false,
			mapTypeControlOptions: { mapTypeIds: ['noText', google.maps.MapTypeId.ROADMAP] }
		};


		let map = new google.maps.Map(document.getElementById('gmap'), myOptions);

		/*スタイルのカスタマイズ*/
		let styleOptions =
		[
		    {
		        "featureType": "all",
		        "elementType": "geometry",
		        "stylers": [
		            {
		                "color": "#dee4ec"
		            }
		        ]
		    },
		    {
		        "featureType": "all",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#616161"
		            }
		        ]
		    },
		    {
		        "featureType": "all",
		        "elementType": "labels.text.stroke",
		        "stylers": [
		            {
		                "color": "#e2e3e4"
		            }
		        ]
		    },
		    {
		        "featureType": "all",
		        "elementType": "labels.icon",
		        "stylers": [
		            {
		                "visibility": "simplified"
		            }
		        ]
		    },
		    {
		        "featureType": "administrative.land_parcel",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#bdbdbd"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "geometry",
		        "stylers": [
		            {
		                "color": "#eeeeee"
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#757575"
		            }
		        ]
		    },
		    {
		        "featureType": "poi.park",
		        "elementType": "geometry",
		        "stylers": [
		            {
		                "color": "#e5e5e5"
		            }
		        ]
		    },
		    {
		        "featureType": "poi.park",
		        "elementType": "labels.text.fill",
		        "stylers": [
		            {
		                "color": "#434a53"
		            }
		        ]
	        }, {
	            "elementType": "all",
	            "featureType": "road",
	            "stylers"    : [
	                {
	                    "saturation": -100
	                }, {
	                    "lightness": 45
	                }
	            ]
	        }, {
	            "elementType": "all",
	            "featureType": "road.highway",
	            "stylers"    : [
	                {
	                    "visibility": "simplified"
	                },
	            ]
		    }, {
		    	"elementType": "geometry",
		        "featureType": "road.highway",
		        "stylers": [
		            {
		                "color": "#cad1db"
		            }
		        ]
	        }, {
	            "elementType": "labels.icon",
	            "featureType": "road.arterial",
	            "stylers"    : [
	                {
	                    "visibility": "off"
	                }
	            ]
	        }, {
	            "elementType": "all",
	            "featureType": "transit",
	            "stylers"    : [
	                {
	                    "visibility": "off"
	                }
	            ]
	        }, {
	            "elementType": "all",
	            "featureType": "water",
	            "stylers"    : [
	                {
	                    "color": "#a8b6c8"
	                }, {
	                    "visibility": "on"
	                }
	            ]
	        }
		];

		let styledMapOptions = { name: 'Hinatamikan' }
		let mapStyle = new google.maps.StyledMapType(styleOptions, styledMapOptions);
		map.mapTypes.set('customStyle', mapStyle);
		map.setMapTypeId('customStyle');


		for (var i = 0; i < data.length; i++) {
			var markers = new google.maps.Marker({
			position: new google.maps.LatLng(data[i].lat, data[i].lng),
			map: map
		});
		//クリックしたら指定したurlに遷移するイベント
		google.maps.event.addListener(markers, event_type, (function(url){
			return function(){ window.open(url); };
				})(data[i].url));
			}
		}
	}

    function validateForm(){
	    var result = true;
	 
	    // エラー用装飾のためのクラスリセット
	    $('#inquiry-name').removeClass("inp_error");
	    $('#inquiry-kana').removeClass("inp_error");
	    $('#inquiry-tell').removeClass("inp_error");
	    $('#inquiry-email').removeClass("inp_error");
	    $('#inquiry-emailConfirm').removeClass("inp_error");
	    $('#inquiry-message').removeClass("inp_error");
	 
	    // 入力エラー文をリセット
	    $("#error-name").empty();
	    $("#error-kana").empty();
	    $("#error-tell").empty();
	    $("#error-email").empty();
	    $("#error-emailConfirm").empty();
	    $("#error-message").empty();
	 
	    // 入力内容セット
	    let errorMessage = "";
	    let name   		  = $("#inquiry-name").val();
	    let kana   		  = $("#inquiry-kana").val();
	    let tell   		  = $("#inquiry-tell").val();
	    let email  		  = $("#inquiry-email").val();
	    let emailConfirm  = $("#inquiry-emailConfirm").val();
	    let message  	  = $("#inquiry-message").val();
	 
	    // 入力内容チェック
	 
	    // お名前
	    if(name == ""){
	        $("#error-name").text("もう一度入力してください。");
	        $("#inquiry-name").addClass("inp_error");
	        result = false;
	    }
	    // お名前
		if(kana == ""){
			$("#error-kana").text("もう一度入力してください。");
			$("#inquiry-kana").addClass("inp_error");
			result = false;
		}
	    // お名前
		if(tell == ""){
			$("#error-tell").text("もう一度入力してください。");
			$("#inquiry-tell").addClass("inp_error");
			result = false;
		}
	    // メールアドレス
	    if(email == ""){
	        $("#error-email").text("もう一度入力してください。");
	        $("#inquiry-email").addClass("inp_error");
	        result = false;
	    } else if (!email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
	        $('#error-email').text("メールアドレスを正しく入力してください。");
	        $("#inquiry-email").addClass("inp_error");
	        result = false;
	    } else if (email.length > 255){
	        $('#error-email').text("メールアドレスを正しく入力してください。");
	        $("#inquiry-email").addClass("inp_error");
	        result = false;
	    }
	    if(emailConfirm == ""){
	        $("#error-emailConfirm").text("もう一度入力してください。");
	        $("#inquiry-emailConfirm").addClass("inp_error");
	        result = false;
	    } else if (email !== emailConfirm){
	        $('#error-emailConfirm').text("メールアドレスを正しく入力してください。");
	        $("#inquiry-emailConfirm").addClass("inp_error");
	        result = false;
	    }
	    // // お問い合わせ内容
	    if(message == ""){
	        $("#error-message").text("もう一度入力してください。");
	        $("#inquiry-message").addClass("inp_error");
	        result = false;
	    }else if(message.match(/[<(.*)>.*<\/\1>]/)){
	        $('#error-message').text("Paste of HTML and URL is prohibited.");
	        $("#inquiry-message").addClass("inp_error");
	        result = false;
	    }else if(message.match(/^[ 　\r\n\t]*$/)){
	        $('#error-message').text("もう一度入力してください。");
	        $("#inquiry-message").addClass("inp_error");
	        result = false;
	    }
	    if (result) {
	    	location.href = "/jp/contact/";
	    } else {
	    	errorMessage = "エラーが発生しました。記入内容を確認して再度送信してください。";
	    	$('.flashMessage').text(errorMessage);
	    	event.preventDefault();
	    	return false;
	    }
	}
	
	/*
	 * 画像着替えアニメーション
	 */
	function setImageAnimate() {
	    let count = setInterval(changeImg, 160);
	    let i = 1;
		function init() {
			let imageAnimateH = $('.js-imageAnimateH').height();
			$('.js-imageAnimate img').css({
				marginLeft: ($window.width() - (imageAnimateH * 1.7777777778))/2,
				width: imageAnimateH * 1.7777777778,
				height: imageAnimateH
			});
		}
	    function changeImg(){
	   		$('.js-imageAnimate').children('img').attr('src','/en/asset/img/top/concept-'+ i + '.jpg');
	        if(i <= 30){
	            i++;
	        }
	        else{
	            i = 1;
	        }
	    }
		$window.on("resize", function(){
			init();
		});
		init();
	}

	/* -----------------------------------------------
	 * ウィンドウリサイズイベント
	 * ----------------------------------------------- */
	function setResize(){
		let timer = false;
		let ww_resized;
		$window.on("orientationchange resize", function(e){
			ww = $window.width();
			wh = $window.height();
			photoGalleryH = $('.js-photoGallery-item img').height();
			onResize();
		});
	}

	function onResize(){
		if (ww > 900) {
			$gnavMenu.css({height: 'auto', display: 'inline-block', opacity: '1'});
			$('.js-drawer').removeClass('is-active');
			$('body').removeClass('menu-open');
		} else {
			if ($('.js-drawer').hasClass('is-active')) {
				$gnavMenu.css({height: wh - (headerH + 20), display: 'inline-block'});
			} else {
				$gnavMenu.css({height: wh - (headerH + 20), display: 'none'});
			}
			$('body').removeClass('menu-open');
		}
		setResponsiveImage();
		if ($('.js-slider--top').length > 0){
			$('.js-slider--top li').height(wh);
			$('.js-slider--top').parent('.bx-viewport').height(wh);
		}
		fixedFooter();
		setAdjustHeight();
	}
})(jQuery,window);


/* -----------------------------------------------
 * Check User Agent
 * ----------------------------------------------- */
function getUA(){

	// 変数
	var agent       = navigator.userAgent; // ユーザーエージェント
	var htmlElement = document.documentElement; // html要素

	// UserAgentで振り分けてhtmlにclassを振る
	if (navigator.platform.search("Mac") != -1) { htmlElement.className += ' mac'; }
	if(agent.search("Windows") != -1){
		htmlElement.className += ' win';
		if((agent.search("Trident") != -1) || ((agent.search("MSIE") != -1))) {
			htmlElement.className += ' ie';
			if(agent.search("Trident") != -1) { htmlElement.className += ' gte_ie8'; }
			if(agent.search("Trident") == -1) { htmlElement.className += ' lte_ie8'; }
			if(agent.search("MSIE 7") != -1){ htmlElement.className += ' ie7'; }
			if(agent.search("MSIE 8") != -1){ htmlElement.className += ' ie8'; }
			if(agent.search("MSIE 9") != -1){ htmlElement.className += ' ie9'; }
			if(agent.search("MSIE 10") != -1){ htmlElement.className += ' ie10'; }
			if(agent.search("Trident/7") != -1){ htmlElement.className += ' ie11'; }
		}
	}
	if((agent.search("Chrome") != -1) && (agent.search("OPR") == -1)){ htmlElement.className += ' chrome'; }
	if((agent.search("Safari") != -1) && (agent.search("Chrome") == -1) && (agent.search("OPR") == -1) && (agent.search("Presto") == -1)) { htmlElement.className += ' safari'; }
	if(agent.search("Firefox") != -1) { htmlElement.className += ' firefox'; }
	if(agent.search("iPad") != -1){ htmlElement.className += ' ipad'; }
	if(agent.search("iPhone") != -1){ htmlElement.className += ' iphone'; }
	if(agent.search('Android') != -1 && agent.search('Mobile') != -1){ htmlElement.className += ' android mobile'; }
	if(agent.search('Android') != -1 && agent.search('Mobile') == -1){ htmlElement.className += ' android tablet'; }

	// スマホ、iPad関連はIE7,8モードで処理
	if(agent.search('iPhone') != -1){
		ua = 'iPhone';
		isSP = true;
	}else if(agent.search('iPad') != -1){
		ua = 'iPad';
		isTAB = true;
	}else if(agent.search('Android') != -1 && agent.search('mobile') != -1){
		ua = 'Android mobile';
		isSP = true;
	}else if(agent.search('Android') != -1 && agent.search('mobile') == -1){
		ua = 'Android tablet';
		isTAB = true;
	}else if(agent.search('MSIE 9') != -1){
		ua = 'IE';
		isIE = true;
	}else{
		ua = "pc";
	};
	return ua;
};



/* -----------------------------------------------
 * Tracer
 * ----------------------------------------------- */
function trace(p, s){
	// console.log(p + " -> " + s);
};